body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.login-content {
  height: 100vh;
}

.login-content div#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
}